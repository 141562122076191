import * as React from 'react'
import styled from 'styled-components'

interface StepProgressBarProps {
    current: number
    max: number
}

const Back = styled.div`
    width: 100%;
    background: ${({ theme }) => theme.palette.primary[100]};
    height: 8px;
    border-radius: 4px;
`

const Progress = styled.div`
    background: ${({ theme }) => theme.palette.primary[500]};
    height: 8px;
    border-radius: 4px;
    transition: width 0.5s cubic-bezier(0.46, -0.72, 0.23, 1.95);
`

const StepProgressBar = ({ current, max }: StepProgressBarProps): JSX.Element => {
    return (
        <Back>
            <Progress
                style={{
                    width: `${(current * 100) / max}%`,
                }}
            />
        </Back>
    )
}

export default StepProgressBar
