import { useTheme } from 'components/hooks'

function File({ fill, style, ...props }: CustomIconProps) {
    const theme = useTheme()
    return (
        <div style={style} {...props}>
            <svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="m26.32 11.107-7.253-8a1.333 1.333 0 0 0-.987-.44H8.747A3.373 3.373 0 0 0 5.333 6v20a3.373 3.373 0 0 0 3.414 3.333h14.506A3.372 3.372 0 0 0 26.667 26V12c-.001-.33-.125-.649-.347-.893ZM23.533 12H18.28a1.053 1.053 0 0 1-.947-1.133V5.333h.147L23.533 12Zm-.28 14.667H8.747A.707.707 0 0 1 8 26V6a.707.707 0 0 1 .747-.667h5.92v5.534a3.72 3.72 0 0 0 3.613 3.8H24V26a.707.707 0 0 1-.747.667Z"
                    fill={fill || theme.palette.text.primary}
                />
            </svg>
        </div>
    )
}

export default File
