import { useTheme } from 'components/hooks'

function EyeOff({ fill, style, ...props }: CustomIconProps) {
    const theme = useTheme()
    return (
        <div style={style} {...props}>
            <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 13.5c-.827 0-1.5-.673-1.5-1.5 0-.013.003-.026.006-.039.003-.012.006-.023.006-.035l1.562 1.562c-.012 0-.024.003-.035.006A.167.167 0 0 1 12 13.5ZM4.707 3.293a.999.999 0 1 0-1.414 1.414l5.63 5.63A3.471 3.471 0 0 0 8.5 12c0 1.93 1.57 3.5 3.5 3.5a3.47 3.47 0 0 0 1.663-.423l5.63 5.63a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-16-16Zm7.513 13.704c-4.305.1-7.115-3.583-8.047-5.002a13.433 13.433 0 0 1 2.283-2.71L5.045 7.871a15.605 15.605 0 0 0-2.912 3.63.999.999 0 0 0 0 .995C2.762 13.594 6.162 19 12.025 19c.082 0 .164 0 .246-.003a9.35 9.35 0 0 0 3.227-.67l-1.58-1.58a7.29 7.29 0 0 1-1.698.251Zm-.49-11.994c5.975-.187 9.5 5.387 10.138 6.5.176.307.176.686 0 .994a15.65 15.65 0 0 1-2.913 3.63l-1.411-1.412a13.392 13.392 0 0 0 2.283-2.71c-.932-1.42-3.755-5.11-8.046-5.003-.6.015-1.163.11-1.7.251l-1.58-1.58a9.357 9.357 0 0 1 3.229-.67Z"
                    fill={fill || theme.palette.text.primary}
                />
                <mask
                    id="a"
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits="userSpaceOnUse"
                    x={2}
                    y={2}
                    width={20}
                    height={19}>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 13.5c-.827 0-1.5-.673-1.5-1.5 0-.013.003-.026.006-.039.003-.012.006-.023.006-.035l1.562 1.562c-.012 0-.024.003-.035.006A.167.167 0 0 1 12 13.5ZM4.707 3.293a.999.999 0 1 0-1.414 1.414l5.63 5.63A3.471 3.471 0 0 0 8.5 12c0 1.93 1.57 3.5 3.5 3.5a3.47 3.47 0 0 0 1.663-.423l5.63 5.63a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-16-16Zm7.513 13.704c-4.305.1-7.115-3.583-8.047-5.002a13.433 13.433 0 0 1 2.283-2.71L5.045 7.871a15.605 15.605 0 0 0-2.912 3.63.999.999 0 0 0 0 .995C2.762 13.594 6.162 19 12.025 19c.082 0 .164 0 .246-.003a9.35 9.35 0 0 0 3.227-.67l-1.58-1.58a7.29 7.29 0 0 1-1.698.251Zm-.49-11.994c5.975-.187 9.5 5.387 10.138 6.5.176.307.176.686 0 .994a15.65 15.65 0 0 1-2.913 3.63l-1.411-1.412a13.392 13.392 0 0 0 2.283-2.71c-.932-1.42-3.755-5.11-8.046-5.003-.6.015-1.163.11-1.7.251l-1.58-1.58a9.357 9.357 0 0 1 3.229-.67Z"
                        fill="#fff"
                    />
                </mask>
                <g mask="url(#a)">
                    <path fill={fill || theme.palette.text.primary} d="M0 0h24v24H0z" />
                </g>
            </svg>
        </div>
    )
}

export default EyeOff
