import { noop } from 'lodash'
import * as React from 'react'
import AutocompleteInput from './components/autocomplete'
import BaseInput from './components/base'
import PasswordInput from './components/password'
import SelectInput from './components/select'
import { InputProps } from './types'

const Input = ({
    inputType,
    options = [],
    onSelect = noop,
    renderOption = () => '',
    ...props
}: InputProps): JSX.Element => {
    switch (inputType) {
        case 'password':
            return <PasswordInput {...props} />
        case 'select':
            return <SelectInput {...props} options={options} />
        case 'autocomplete':
            return <AutocompleteInput {...props} options={options} renderOption={renderOption} onSelect={onSelect} />
        default:
            return <BaseInput {...props} />
    }
}

export default Input
