import Icon from 'components/atomics/atoms/icon'
import Input from 'components/atomics/atoms/input'
import NextStepButton from 'components/atomics/molecules/next-step-button'
import StepProgressBar from 'components/atomics/molecules/step-progress-bar'
import PageLayout from 'components/atomics/organisms/page-layout'
import { useForm, useStoreListener, useTheme, useTranslation } from 'components/hooks'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSchools, verifyEmail } from 'store/modules/student/student.actions'
import { StudentActions } from 'store/modules/student/student.consts'
import { studentLoadingSelector, studentSchoolsSelector } from 'store/modules/student/student.selectors'
import styled from 'styled-components'
import createValidationSchema from 'utils/validation'

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    padding-top: 1rem;
`

const Header = styled.div`
    h2 {
        text-align: center;
    }

    h4 {
        text-align: center;
    }

    p {
        text-align: center;
    }
`

const VerifyEmailPage = (): JSX.Element => {
    const theme = useTheme()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const schools = useSelector(studentSchoolsSelector)
    const loading = useSelector(studentLoadingSelector)

    const [step, setStep] = React.useState<number>(1)

    const form = useForm({
        initialValues: {
            school: '',
            email: '',
        },
        onSubmit: ({ school, email }) => {
            // todo:
            const schoolData = schools?.find((s) => school === `${s.type} - ${s.name} (${s.code})`)
            console.log(school, email, schoolData, schools)
            if (schoolData) {
                dispatch(verifyEmail({ email, school_code: schoolData.code }))
            }
        },
        validateOnMount: true,
        validationSchema: createValidationSchema({
            school: {
                type: 'string',
                rules: {
                    required: { error: t('errors.required') },
                },
            },
            email: {
                type: 'string',
                rules: {
                    required: { error: t('errors.required') },
                    email: { error: t('errors.notValid') },
                },
            },
        }),
    })

    const isNextStepButtonEnabled = React.useMemo(() => {
        if (step === 1) return form.values.school !== undefined && form.values.school.length > 0
        else if (step === 2) return form.isValid
        return true
    }, [form.isValid, form.values.school, step])

    const handleNextStep = (): void => {
        if (step < 2) {
            setStep((curr) => curr + 1)
        } else {
            handleSendEmail()
        }
    }

    const handleSendEmail = React.useCallback((): void => {
        form.handleSubmit()
    }, [form])

    const handleSchoolSearch = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (event && event.target.value) dispatch(getSchools({ name: event.target.value }))
        },
        [dispatch],
    )

    useStoreListener({
        [StudentActions.VERIFY_EMAIL_SUCCESS]: () => {
            setStep(3)
        },
    })

    const content = React.useMemo(() => {
        switch (step) {
            case 1:
                return (
                    <Content>
                        <Header>
                            <h2>{t('pages.VerifyEmail.title')}</h2>
                            <p>{t('pages.VerifyEmail.subtitle1')}</p>
                        </Header>
                        <Input
                            inputType="autocomplete"
                            label={t('pages.VerifyEmail.labelIstituto')}
                            placeholder={t('pages.VerifyEmail.placeholderIstituto')}
                            value={form.values.school}
                            onChange={handleSchoolSearch}
                            onSelect={form.handleChange('school')}
                            onBlur={form.handleBlur('school')}
                            error={form.touched.school && form.errors.school !== undefined}
                            helperText={
                                form.touched.school && form.errors.school !== undefined ? form.errors.school : undefined
                            }
                            options={schools?.map((s) => ({
                                value: s.name,
                                label: s.name,
                                data: s,
                            }))}
                            renderOption={(o): string => `${o.data.type} - ${o.data.name} (${o.data.code})`}
                        />
                    </Content>
                )
            case 2:
                return (
                    <Content>
                        <Header>
                            <h2>{t('pages.VerifyEmail.title')}</h2>
                            <p>{t('pages.VerifyEmail.subtitle2')}</p>
                        </Header>
                        <Input
                            label={t('pages.VerifyEmail.labelIstituto')}
                            placeholder={t('pages.VerifyEmail.placeholderIstituto')}
                            disabled
                            iconRight="edit"
                            onIconRightClick={() => setStep(1)}
                            value={form.values.school}
                        />
                        <Input
                            label={t('pages.VerifyEmail.labelEmail')}
                            placeholder={t('pages.VerifyEmail.placeholderEmail')}
                            value={form.values.email}
                            onChange={form.handleChange('email')}
                            error={form.touched.email && form.errors.email !== undefined}
                            helperText={
                                form.touched.email && form.errors.email !== undefined ? form.errors.email : undefined
                            }
                            onBlur={form.handleBlur('email')}
                        />
                    </Content>
                )
            case 3:
                return (
                    <Content style={{ alignItems: 'center', justifyContent: 'center', padding: '0 2rem' }}>
                        <Icon name="sync" fill={theme.palette.primary.main} style={{ height: 64, width: 64 }} />
                        <Header>
                            <h4>{t('pages.VerifyEmail.emailSent', { email: form.values.email })}</h4>
                            <p>{t('pages.VerifyEmail.clickOnLink')}</p>
                            <p>
                                {t('pages.VerifyEmail.emailNotReceived')}{' '}
                                <span className="link" onClick={handleSendEmail}>
                                    {t('labels.clickHere')}
                                </span>
                            </p>
                        </Header>
                    </Content>
                )
        }
    }, [form, handleSchoolSearch, handleSendEmail, schools, step, t, theme.palette.primary.main])

    return (
        <PageLayout>
            <Wrapper>
                <div style={{ flex: 1 }}>
                    <StepProgressBar current={step} max={3} />
                    {content}
                </div>
                <NextStepButton
                    onNextClick={handleNextStep}
                    enabled={isNextStepButtonEnabled}
                    hideButton={step === 3}
                    loading={loading}
                />
            </Wrapper>
        </PageLayout>
    )
}

export default VerifyEmailPage
