import Icon from 'components/atomics/atoms/icon'
import Input from 'components/atomics/atoms/input'
import NextStepButton from 'components/atomics/molecules/next-step-button'
import StepProgressBar from 'components/atomics/molecules/step-progress-bar'
import PageLayout from 'components/atomics/organisms/page-layout'
import { useStoreListener, useTheme, useTranslation } from 'components/hooks'
import { useForm } from 'components/hooks'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSchools, verifyDocuments } from 'store/modules/student/student.actions'
import { StudentActions } from 'store/modules/student/student.consts'
import { studentLoadingSelector, studentSchoolsSelector } from 'store/modules/student/student.selectors'
import styled from 'styled-components'
import createValidationSchema from 'utils/validation'
import DragAndDrop from '../../components/atomics/organisms/drag-and-drop'

const mockedDocs = ['Documento 1', 'Documento 2', 'Documento 3', 'Documento 4']

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    padding-top: 1rem;
`

const ContentInfo = styled.div`
    padding: 1rem 1rem 0rem 1rem;
`

const ListItem = styled.li`
    list-style-type: square;
    margin: 4px 0;
`

const Header = styled.div`
    h2 {
        text-align: center;
    }

    h4 {
        text-align: center;
    }

    p {
        text-align: center;
    }
`

const UploadDocumentsPage = (): JSX.Element => {
    const { t } = useTranslation()
    const theme = useTheme()
    const dispatch = useDispatch()

    const schools = useSelector(studentSchoolsSelector)
    const loading = useSelector(studentLoadingSelector)

    const [step, setStep] = React.useState<number>(1)
    const [files, setFiles] = React.useState<File[]>()

    const form = useForm({
        initialValues: {
            school: '',
            email: '',
        },
        onSubmit: ({ school, email }) => {
            const schoolData = schools?.find((s) => school === `${s.type} - ${s.name} (${s.code})`)
            console.log(school, email, schoolData, schools)
            if (schoolData && files) {
                dispatch(verifyDocuments({ email, school_code: schoolData.code, files }))
            }
        },
        validateOnMount: true,
        validationSchema: createValidationSchema({
            school: {
                type: 'string',
                rules: {
                    required: { error: t('errors.required') },
                },
            },
            email: {
                type: 'string',
                rules: {
                    required: { error: t('errors.required') },
                    email: { error: t('errors.notValid') },
                },
            },
        }),
    })

    const handleNextStep = (): void => {
        if (step < 2) {
            setStep((curr) => curr + 1)
        } else {
            handleSendEmail()
        }
    }

    const handleSendEmail = React.useCallback((): void => {
        form.handleSubmit()
    }, [form])

    const handleSchoolSearch = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (event && event.target.value) dispatch(getSchools({ name: event.target.value }))
        },
        [dispatch],
    )

    useStoreListener({
        [StudentActions.VERIFY_DOCUMENTS_SUCCESS]: () => {
            setStep(3)
        },
    })

    const isNextStepButtonEnabled = React.useMemo(() => {
        if (step === 2) return form.isValid && files !== undefined && files.length > 0
        return true
    }, [files, form.isValid, step])

    const content = React.useMemo(() => {
        switch (step) {
            case 1:
                return (
                    <Content>
                        <ContentInfo>
                            <Header>
                                <h2>{t('pages.UploadDocuments.title')}</h2>
                                <p>{t('pages.UploadDocuments.subtitle1')}</p>
                            </Header>
                            <h4>{t('pages.UploadDocuments.mandatoryDocs')}</h4>
                            <ul style={{ margin: 0 }}>
                                {mockedDocs.map((d, index) => (
                                    <ListItem key={`${index}_${d}`}>{d}</ListItem>
                                ))}
                            </ul>
                        </ContentInfo>
                    </Content>
                )
            case 2:
                return (
                    <Content>
                        <ContentInfo>
                            <Header>
                                <h2>{t('pages.UploadDocuments.title')}</h2>
                                <p>{t('pages.UploadDocuments.subtitle2')}</p>
                            </Header>
                        </ContentInfo>
                        <Input
                            inputType="autocomplete"
                            label="Istituto"
                            placeholder="es.: IS Pascal Comandini"
                            value={form.values.school}
                            onBlur={form.handleBlur('school')}
                            onChange={handleSchoolSearch}
                            onSelect={form.handleChange('school')}
                            error={form.touched.school && form.errors.school !== undefined}
                            helperText={
                                form.touched.school && form.errors.school !== undefined ? form.errors.school : undefined
                            }
                            options={schools?.map((s) => ({
                                value: s.name,
                                label: s.name,
                                data: s,
                            }))}
                            renderOption={(o): string => `${o.data.type} - ${o.data.name} (${o.data.code})`}
                        />
                        <Input
                            label="Email"
                            placeholder="es.: nomecognome@mail.it"
                            value={form.values.email}
                            onChange={form.handleChange('email')}
                            onBlur={form.handleBlur('email')}
                            error={form.touched.email && form.errors.email !== undefined}
                            helperText={
                                form.touched.email && form.errors.email !== undefined ? form.errors.email : undefined
                            }
                        />
                        <DragAndDrop
                            files={files}
                            updateFiles={(acceptedFiles) => {
                                setFiles((curr) => [...(files || []), ...acceptedFiles])
                            }}
                            removeFile={(index) => {
                                setFiles((curr) => {
                                    const tmp = [...(curr || [])]
                                    tmp.splice(index, 1)
                                    return tmp
                                })
                            }}
                        />
                    </Content>
                )
            case 3:
                return (
                    <Content style={{ alignItems: 'center', justifyContent: 'center', padding: '0 2rem' }}>
                        <Icon name="sync" fill={theme.palette.primary.main} style={{ height: 64, width: 64 }} />
                        <Header>
                            <h4>{t('pages.UploadDocuments.subtitle3')}</h4>
                            <p>{t('pages.UploadDocuments.checkingDocs')}</p>
                        </Header>
                    </Content>
                )
        }
    }, [files, form, handleSchoolSearch, schools, step, t, theme.palette.primary.main])

    return (
        <PageLayout>
            <Wrapper>
                <div style={{ flex: 1 }}>
                    <StepProgressBar current={step} max={3} />
                    {content}
                </div>
                <NextStepButton
                    onNextClick={handleNextStep}
                    enabled={isNextStepButtonEnabled}
                    hideButton={step === 3}
                    loading={loading}
                />
            </Wrapper>
        </PageLayout>
    )
}

export default UploadDocumentsPage
