import { createStore, IModuleStore } from 'redux-dynamic-modules'
import { getSagaExtension } from 'redux-dynamic-modules-saga'
import { combineReducers } from 'redux'

import concatenateReducers from 'utils/redux/concatenate-reducers'

import getActionsEmitterExtension from './extensions/actions-emitter'
import { getRootModule, getStudentModule } from './modules'
import { MainState } from './types'
import getErrorHandlerExtension from './extensions/error-handler'

const sagaContext = {}

const store: IModuleStore<MainState> = createStore(
    {
        initialState: {},
        enhancers: [],
        extensions: [getSagaExtension(sagaContext), getActionsEmitterExtension(), getErrorHandlerExtension()],
        advancedCombineReducers: (allReducers) => {
            const { root, ...reducers } = allReducers as unknown as {
                root: RootState
            }
            return concatenateReducers([root, combineReducers(reducers)])
        },
    },
    getRootModule(),
    getStudentModule(),
)

export default store
