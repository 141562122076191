import Icon from 'components/atomics/atoms/icon'
import Loader from 'components/atomics/atoms/loader'
import NextStepButton from 'components/atomics/molecules/next-step-button'
import StepProgressBar from 'components/atomics/molecules/step-progress-bar'
import PageLayout from 'components/atomics/organisms/page-layout'
import { useStoreListener, useTheme, useTranslation } from 'components/hooks'
import { noop } from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { verifyWSToken } from 'store/modules/student/student.actions'
import { StudentActions } from 'store/modules/student/student.consts'
import { studentUserIdSelector } from 'store/modules/student/student.selectors'
import styled from 'styled-components'

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    padding-top: 1rem;
`

const Header = styled.div`
    h2 {
        text-align: center;
    }

    h4 {
        text-align: center;
        color: ${({ theme }) => theme.palette.text.secondary};
    }

    p {
        text-align: center;
    }
`

const WeStudentsLoginPage = (): JSX.Element => {
    const theme = useTheme()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()

    const userId = useSelector(studentUserIdSelector)

    const [status, setStatus] = React.useState<'loading' | 'verified' | 'rejected'>('loading')

    React.useEffect(() => {
        const token = searchParams.get('access_token')
        if (token !== null && userId !== undefined) {
            dispatch(verifyWSToken({ token, userId }))
        }
    }, [dispatch, searchParams, userId])

    useStoreListener({
        [StudentActions.VERIFY_WS_TOKEN_SUCCESS]: (payload) => {
            const { verified } = payload
            setStatus(verified ? 'verified' : 'rejected')
        },
    })

    const content = React.useMemo(() => {
        switch (status) {
            case 'loading':
                return (
                    <Content style={{ alignItems: 'center', justifyContent: 'center', padding: '0 2rem' }}>
                        <Loader color={theme.palette.primary.main} />
                    </Content>
                )
            case 'verified':
                return (
                    <Content style={{ alignItems: 'center', justifyContent: 'center', padding: '0 2rem' }}>
                        <Icon name="checkmark" fill={theme.palette.primary.main} style={{ height: 64, width: 64 }} />
                        <Header>
                            <h4>{t('pages.WeStudentsLogin.verified')}</h4>
                        </Header>
                    </Content>
                )
            case 'rejected':
                return (
                    <Content style={{ alignItems: 'center', justifyContent: 'center', padding: '0 2rem' }}>
                        <Icon name="close" fill={theme.palette.error.main} style={{ height: 64, width: 64 }} />
                        <Header>
                            <h4>{t('pages.WeStudentsLogin.rejected')}</h4>
                        </Header>
                        <NextStepButton hideButton onNextClick={noop} />
                    </Content>
                )
        }
    }, [status, t, theme.palette.error.main, theme.palette.primary.main])

    return (
        <PageLayout>
            <Wrapper>
                <div style={{ flex: 1 }}>
                    <StepProgressBar current={1} max={1} />
                    {content}
                </div>
            </Wrapper>
        </PageLayout>
    )
}

export default WeStudentsLoginPage
