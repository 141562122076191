import { useTheme } from 'components/hooks'

function Question({ fill, style, ...props }: CustomIconProps) {
    const theme = useTheme()
    return (
        <div style={style} {...props}>
            <svg fill="none" viewBox="0 0 24 24" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.5 9.5C8.5 7.57 10.07 6 12 6s3.5 1.57 3.5 3.5c0 1.58-1.06 2.903-2.5 3.337V14a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1c.827 0 1.5-.673 1.5-1.5S12.827 8 12 8s-1.5.673-1.5 1.5a1 1 0 1 1-2 0ZM11 17a1 1 0 1 1 2 0 1 1 0 1 1-2 0Zm1 3c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8Zm0-18C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2Z"
                    fill={fill || theme.palette.text.primary}
                />
            </svg>
        </div>
    )
}

export default Question
