import { IconButton, InputAdornment, TextField } from '@mui/material'
import Icon from 'components/atomics/atoms/icon'
import * as React from 'react'
import styled from 'styled-components'
import { InputProps } from '../../types'

const StyledInput = styled(TextField)`
    & .MuiOutlinedInput-root {
        border-radius: 5px;
        position: relative;
        font-size: 16;

        & fieldset {
            border-color: ${({ theme }) => theme.palette.primary[500]};
            margin: 0;
        }
        &:hover fieldset {
            border-color: #ff9d64;
        }
        &.Mui-focused fieldset {
            border-color: #ff9d64;
        }
    }
`

const InputWrapper = styled.div`
    width: 100%;
    margin: 0 0 0.5rem 0;
`

const Label = styled.p`
    color: ${({ theme }) => theme.palette.text.primary};
    margin: 0 0 4px 0;
    font-weight: 700;
    font-size: 12px;
`

const BaseInput = ({ label, iconRight, onIconRightClick, onSelect, ...props }: InputProps): JSX.Element => {
    return (
        <InputWrapper>
            <Label>{label}</Label>
            <StyledInput
                fullWidth
                variant="outlined"
                InputProps={{
                    endAdornment: iconRight ? (
                        <InputAdornment position="end">
                            <IconButton onClick={onIconRightClick}>
                                <Icon name={iconRight} />
                            </IconButton>
                        </InputAdornment>
                    ) : undefined,
                }}
                {...props}
            />
        </InputWrapper>
    )
}

export default BaseInput
