import WsSdkError from 'utils/ws-sdk/utils/ws-sdk-error'

export const studentReadySelector = (state: RootState): boolean | undefined => {
    return state.student?.ready
}

export const studentInitializedSelector = (state: RootState): boolean | undefined => {
    return state.student?.initizialized
}

export const studentLoadingSelector = (state: RootState): boolean | undefined => {
    return state.student?.loading
}

export const studentErrorSelector = (state: RootState): WsSdkError | undefined => {
    return state.student?.error
}

export const studentSchoolsSelector = (state: RootState): School[] | undefined => {
    return state.student?.schools
}

export const studentVerifiedStatusSelector = (state: RootState): boolean | undefined => {
    return state.student?.verified
}

export const studentRequestsSelector = (state: RootState): VerifyRequest[] | undefined => {
    return state.student?.requests
}

export const studentUserIdSelector = (state: RootState): string | undefined => {
    return state.student?.userId
}

export const studentOptionsSelector = (state: RootState): ConfigOptions | undefined => {
    return state.student?.options
}
