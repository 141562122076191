import * as React from 'react'
import PageLayout from 'components/atomics/organisms/page-layout'
import styled from 'styled-components'
import MethodItem, { Method } from './components/method-item'
import { useConfigParams, useStoreListener, useTranslation } from 'components/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { init } from 'store/modules/student/student.actions'
import {
    studentInitializedSelector,
    studentOptionsSelector,
    studentReadySelector,
} from 'store/modules/student/student.selectors'
import { StudentActions } from 'store/modules/student/student.consts'
import { useNavigate } from 'react-router-dom'
import ErrorComponent from 'components/atomics/atoms/error'

const Divider = styled.div`
    background-color: ${({ theme }) => theme.palette.divider};
    height: 0.5px;
`

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;

    h2 {
        text-align: center;
    }

    p {
        text-align: center;
    }
`

const MethodsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
`

const SelectMethodPage = (): JSX.Element => {
    const { t } = useTranslation()
    const { apiKey, userId, wsLoginEnabled, electronicRegisterEnabled } = useConfigParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const storeOptions = useSelector(studentOptionsSelector)
    const isReady = useSelector(studentReadySelector)
    const isInitialized = useSelector(studentInitializedSelector)

    const [content, setContent] = React.useState<JSX.Element>()

    const renderMethodItem = React.useCallback(
        (m: Method) => (
            <div key={m}>
                <MethodItem method={m} />
                <Divider />
            </div>
        ),
        [],
    )

    const methods: Method[] = React.useMemo(() => {
        let res: Method[] = ['email', 'docs']
        if (storeOptions?.electronicRegisterEnabled === true) res = [...res, 'electronic-register']
        if (storeOptions?.wsLoginEnabled === true) {
            res = [...res, 'wslogin']
        }
        console.log('options', storeOptions)
        return res
    }, [storeOptions])

    const SelectMethodContent = React.useMemo(
        () => (
            <>
                <div>
                    <h2>{t('pages.SelectMethod.title')}</h2>
                    <p>{t('pages.SelectMethod.subtitle')}</p>
                </div>
                <MethodsContainer>
                    <Divider />
                    {methods.map(renderMethodItem)}
                </MethodsContainer>
            </>
        ),
        [methods, renderMethodItem, t],
    )

    React.useEffect(() => {
        if (isReady) {
            if (isInitialized) {
                setContent(SelectMethodContent)
            } else {
                if (apiKey !== null) {
                    dispatch(
                        init({
                            apiKey,
                            userId,
                            options: {
                                wsLoginEnabled,
                                electronicRegisterEnabled,
                            },
                        }),
                    )
                } else {
                    setContent(ErrorComponent)
                }
            }
        }
    }, [
        apiKey,
        isReady,
        userId,
        wsLoginEnabled,
        electronicRegisterEnabled,
        storeOptions,
        dispatch,
        SelectMethodContent,
        isInitialized,
    ])

    useStoreListener({
        [StudentActions.INIT_SUCCESS]: (payload) => {
            const { requests } = payload as { requests?: VerifyRequest[] }
            if (requests !== undefined && requests.length > 0) {
                navigate('/pending-requests')
            }
        },
    })

    return (
        <PageLayout>
            <Wrapper>{content}</Wrapper>
        </PageLayout>
    )
}

export default SelectMethodPage
