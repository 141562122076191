import { initialState as studentInitialState } from '../student/student.consts'
export const STATE_KEY = 'root'

export const initialState: RootState = {
    loading: false,
    student: studentInitialState,
}

const RootActionsIdentifiers = {
    CLEAN: `${STATE_KEY}/CLEAN`,
    CLEAN_DATA: `${STATE_KEY}/CLEAN_DATA`,
}

export default RootActionsIdentifiers
