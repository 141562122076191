import * as React from 'react'
import styled from 'styled-components'

const Error = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3 {
        margin: 0;
        text-align: center;
        color: ${({ theme }) => theme.palette.error[500]};
    }

    p {
        margin: 0;
        color: ${({ theme }) => theme.palette.text.secondary};
    }
`

const ErrorComponent = (): JSX.Element => {
    return (
        <Error>
            <h3>Ops, qualcosa non ha funzionato correttamente</h3>
            <p>Se sei uno studente prova a ripetere la verifica.</p>
            <p>
                Se sei uno sviluppatore clicca&nbsp;
                <a href="https://ws-developers-docs.netlify.app/api-reference/#/">qui</a>.
            </p>
        </Error>
    )
}

export default ErrorComponent
