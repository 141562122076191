import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import it from './it.json'

i18n.use(initReactI18next).init({
    resources: {
        it,
    },
    lng: 'it',

    fallbackLng: 'it',

    interpolation: {
        escapeValue: false,
    },
})
