import { Autocomplete } from '@mui/material'
import * as React from 'react'
import { AutocompleteInputProps } from '../../types'
import BaseInput from '../base'

const AutocompleteInput = ({
    options,
    renderOption,
    value,
    onSelect,
    onChange,
    ...props
}: AutocompleteInputProps): JSX.Element => {
    const [innerValue, setInnerValue] = React.useState<string>('')

    return (
        <Autocomplete
            id="combo-box-demo"
            freeSolo
            value={value}
            onChange={(event, newValue) => onSelect(newValue as string)}
            inputValue={innerValue}
            onInputChange={(event, newInputValue) => {
                if (newInputValue && newInputValue.length > 0) {
                    onChange && onChange(event.nativeEvent as unknown as React.ChangeEvent<HTMLInputElement>)
                }
                setInnerValue(newInputValue)
            }}
            options={options.map(renderOption)}
            renderInput={(params) => <BaseInput {...params} {...props} />}
        />
    )
}

export default AutocompleteInput
