import NextStepButton from 'components/atomics/molecules/next-step-button'
import PageLayout from 'components/atomics/organisms/page-layout'
import { useTheme, useTranslation } from 'components/hooks'
import { noop } from 'lodash'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { studentRequestsSelector } from 'store/modules/student/student.selectors'
import styled from 'styled-components'

const RequestItem = styled.div`
    padding: 1rem;
    /* border-top: 1px solid ${({ theme }) => theme.palette.divider}; */
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
        margin: 0;
    }

    p {
        margin: 0;
    }

    & .status {
        font-weight: 700;
    }

    & .email {
        color: ${({ theme }) => theme.palette.text.secondary};
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    h2 {
        text-align: center;
    }
`

const ListContainer = styled.div`
    flex-grow: 1;
    margin-top: 1rem;
    border-top: 1px solid ${({ theme }) => theme.palette.divider};
`

const PendingRequestsPage = (): JSX.Element => {
    const requests = useSelector(studentRequestsSelector)
    const { t } = useTranslation()
    const theme = useTheme()
    return (
        <PageLayout>
            <Wrapper>
                <h2>{t('pages.PendingRequests.title')}</h2>
                <ListContainer>
                    {requests?.map((request) => (
                        <RequestItem key={request.type}>
                            <div>
                                <h4>{t(`labels.methods.${request.type}`)}</h4>
                                <p className="email">{request.email}</p>
                            </div>
                            <p
                                className="status"
                                style={{
                                    color:
                                        request.status === 'PENDING'
                                            ? theme.palette.warning.main
                                            : request.status === 'REJECTED'
                                            ? theme.palette.error.main
                                            : theme.palette.success.main,
                                }}>
                                {t(`labels.status.${request.status}`)}
                            </p>
                        </RequestItem>
                    ))}
                </ListContainer>
                <NextStepButton onNextClick={noop} hideButton />
            </Wrapper>
        </PageLayout>
    )
}

export default PendingRequestsPage
