import { useTheme } from 'components/hooks'

function Book({ fill, style, ...props }: CustomIconProps) {
    const theme = useTheme()
    return (
        <div style={style} {...props}>
            <svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M25.333 4h-16a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h16a1.333 1.333 0 0 0 1.334-1.333V5.333A1.333 1.333 0 0 0 25.333 4Zm-16 2.667H24V20H9.333A4 4 0 0 0 8 20.24V8a1.333 1.333 0 0 1 1.333-1.333Zm0 18.666a1.333 1.333 0 1 1 0-2.666H24v2.666H9.333Z"
                    fill={fill || theme.palette.text.primary}
                />
            </svg>
        </div>
    )
}

export default Book
