import React from 'react'
import ThemeProvider from 'components/providers/theme'
import { Route, Routes, useNavigate } from 'react-router-dom'
import {
    ElectronicRegisterPage,
    NotFoundPage,
    PendingRequestsPage,
    SelectMethodPage,
    UploadDocumentsPage,
    VerifyEmailPage,
    WeStudentsLoginPage,
} from 'pages'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import theme from 'components/providers/theme/defaultTheme'
import styled from 'styled-components'

import 'assets/locales'
import { useDispatch, useSelector } from 'react-redux'
import {
    studentRequestsSelector,
    studentUserIdSelector,
    studentVerifiedStatusSelector,
} from 'store/modules/student/student.selectors'
import { useStoreListener } from 'components/hooks'
import { StudentActions } from 'store/modules/student/student.consts'

import 'configs/localforage'
import { cleanData } from 'store/modules/root/root.actions'

const GlobalStyles = styled.div`
    h1 {
        font-weight: 700;
        font-size: 3rem;
        margin: 0;
    }

    h2 {
        font-weight: 700;
        font-size: 2rem;
        margin: 0;
    }

    h3 {
        font-weight: 600;
        margin: 0;
    }

    a {
        color: ${({ theme }) => theme.palette.primary.main};
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .link {
        color: ${({ theme }) => theme.palette.primary.main};
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
`

function App() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const verified = useSelector(studentVerifiedStatusSelector)
    const requests = useSelector(studentRequestsSelector)
    const userId = useSelector(studentUserIdSelector)

    React.useEffect(() => {
        console.log('mounting')

        return () => {
            console.log('unmounting')
        }
    }, [])

    React.useEffect(() => {
        const originalWarn = console.warn.bind(console.warn)

        console.warn = (msg) =>
            !msg
                .toString()
                .includes('MUI: You have provided an out-of-range value `undefined` for the select component.') &&
            originalWarn(msg)

        return () => {
            console.warn = originalWarn
        }
    }, [])

    React.useEffect(() => {
        if (verified && userId) {
            window.opener.postMessage({ userId, verified, status: 'VERIFIED' }, '*')
            console.log('opener', window.opener)
            dispatch(cleanData())
            window.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, requests, userId, verified])

    useStoreListener({
        [StudentActions.VERIFY_DOCUMENTS_SUCCESS]: (payload) => {
            const { user_id: userId, verified, status } = payload
            setTimeout(() => {
                dispatch(cleanData())
                window.opener.postMessage({ userId, verified, status }, '*')
                window.close()
            }, 1000)
        },
        [StudentActions.VERIFY_EMAIL_SUCCESS]: (payload) => {
            const { user_id: userId, verified, status } = payload
            setTimeout(() => {
                dispatch(cleanData())
                window.opener.postMessage({ userId, verified, status }, '*')
                window.close()
            }, 1000)
        },
        [StudentActions.VERIFY_WS_TOKEN_SUCCESS]: (payload) => {
            const { user_id: userId, verified, status } = payload
            window.opener.postMessage({ userId, verified, status }, '*')
        },
    })

    return (
        <div className="App">
            <ThemeProvider>
                <MuiThemeProvider theme={theme}>
                    <GlobalStyles>
                        <Routes>
                            <Route path="/methods" element={<SelectMethodPage />} />
                            <Route path="/email" element={<VerifyEmailPage />} />
                            <Route path="/documents" element={<UploadDocumentsPage />} />
                            <Route path="/westudents" element={<WeStudentsLoginPage />} />
                            <Route path="/electronic-register" element={<ElectronicRegisterPage />} />
                            <Route path="/pending-requests" element={<PendingRequestsPage />} />
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </GlobalStyles>
                </MuiThemeProvider>
            </ThemeProvider>
        </div>
    )
}

export default App
