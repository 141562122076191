export const getEnv = (): { [key: string]: string | undefined } => {
    return {
        ENV: process.env.NODE_ENV,
        BASE_URL: process.env.REACT_APP_BASE_URL,
        WS_LOGIN_CLIENT_ID: process.env.REACT_APP_WS_LOGIN_CLIENT_ID,
        WS_LOGIN_REDIRECT_URI: process.env.REACT_APP_WS_LOGIN_REDIRECT_URI,
        API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
        INDEXED_DB_NAME: process.env.REACT_APP_INDEXED_DB_NAME,
        INDEXED_DB_STORE_DESCRIPTION: process.env.REACT_APP_INDEXED_DB_STORE_DESCRIPTION,
        INDEXED_DB_STORE_NAME: process.env.REACT_APP_INDEXED_DB_STORE_NAME,
        INDEXED_DB_VERSION: process.env.REACT_APP_INDEXED_DB_VERSION,
    }
}
