import { useTheme } from 'components/hooks'

function Edit({ fill, style, ...props }: CustomIconProps) {
    const theme = useTheme()
    return (
        <div style={style} {...props}>
            <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M19.4 7.34 16.66 4.6A2 2 0 0 0 14 4.53l-9 9a2 2 0 0 0-.57 1.21L4 18.91A1 1 0 0 0 5 20h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71ZM9.08 17.62l-3 .28.27-3L12 9.32l2.7 2.7-5.62 5.6ZM16 10.68 13.32 8l1.95-2L18 8.73l-2 1.95Z"
                    fill={fill || theme.palette.text.primary}
                />
            </svg>
        </div>
    )
}

export default Edit
