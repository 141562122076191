import { useSearchParams } from 'react-router-dom'

interface ConfigParams {
    apiKey: string | null
    userId: string | null
    wsLoginEnabled: boolean
    electronicRegisterEnabled: boolean
}

const useConfigParams = (): ConfigParams => {
    const [searchParams] = useSearchParams()

    const apiKey = searchParams.get('api_key')
    const userId = searchParams.get('user_id')
    const wsLoginEnabled = searchParams.get('ws_login_enabled')
    const electronicRegisterEnabled = searchParams.get('electronic_register_enabled')

    return {
        apiKey: apiKey === null || apiKey.length === 0 ? null : apiKey,
        userId: userId === null || userId.length === 0 ? null : userId,
        wsLoginEnabled: wsLoginEnabled === null ? true : JSON.parse(wsLoginEnabled),
        electronicRegisterEnabled: electronicRegisterEnabled === null ? true : JSON.parse(electronicRegisterEnabled),
    }
}

export default useConfigParams
