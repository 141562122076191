import * as React from 'react'
import { Container } from '@mui/material'
import styled from 'styled-components'

const Wrapper = styled(Container)`
    > div {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`

const Card = styled.div`
    background: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 0.5rem;
    box-sizing: border-box;
    padding: 2rem;
    width: 45%;
    aspect-ratio: 0.8;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        width: 95%;
        aspect-ratio: unset;
    }
`
interface PageLayoutProps {
    children: JSX.Element
}

const PageLayout = ({ children }: PageLayoutProps): JSX.Element => {
    return (
        <Wrapper>
            <div>
                <Card>{children}</Card>
                <p>
                    Serve aiuto? <a href="mailto:info@westudents.it">Contattaci</a>
                </p>
            </div>
        </Wrapper>
    )
}

export default PageLayout
