import { useTheme } from 'components/hooks'

function Email({ fill, style, ...props }: CustomIconProps) {
    const theme = useTheme()
    return (
        <div style={style} {...props}>
            <svg {...props} viewBox="0 0 32 32">
                <path
                    d="M25.333 5.333H6.667a4 4 0 0 0-4 4v13.334a4 4 0 0 0 4 4h18.666a4 4 0 0 0 4-4V9.333a4 4 0 0 0-4-4ZM24.44 8 16 14.333 7.56 8h16.88Zm.893 16H6.667a1.334 1.334 0 0 1-1.334-1.333v-13l9.867 7.4a1.333 1.333 0 0 0 1.6 0l9.867-7.4v13A1.334 1.334 0 0 1 25.333 24Z"
                    fill={fill || theme.palette.text.primary}
                />
            </svg>
        </div>
    )
}

export default Email
