import { IconButton } from '@mui/material'
import Icon from 'components/atomics/atoms/icon'
import { useTheme, useWindowSize } from 'components/hooks'
import * as React from 'react'
import styled from 'styled-components'

const FileRow = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        width: 100%;
        padding: 0.3rem 0;
    }
`

const FileName = styled.div`
    margin: 0 0 0 0.4rem;
    display: flex;
    flex: 1;
    font-size: 14px;
    min-width: 0;
    min-height: 0;

    p {
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`

const FileSize = styled.p`
    margin: 0 0 0 0.4rem;
    width: 2.5rem;
    font-size: 12px;
    border-radius: 3px;
    color: ${({ theme }) => theme.palette.text.seconday};
    border: 1px solid ${({ theme }) => theme.palette.text.seconday};
    padding: 0 4px;
    font-weight: 600;
    text-align: center;
`

const UploadSuccess = styled.p`
    margin: 0 0 0 0.4rem;
    width: 3rem;
    font-size: 12px;
    border-radius: 3px;
    color: ${({ theme }) => theme.palette.success[500]};
    border: 1px solid ${({ theme }) => theme.palette.success[500]};
    padding: 0 4px;
    font-weight: 200;
    text-align: center;
`

const UploadFailure = styled.p`
    margin: 0 0 0 0.4rem;
    width: 3rem;
    font-size: 12px;
    border-radius: 3px;
    color: ${({ theme }) => theme.palette.error[500]};
    border: 1px solid ${({ theme }) => theme.palette.error[500]};
    padding: 0 4px;
    font-weight: 200;
    text-align: center;
`

const Tags = styled.div`
    display: flex;
    flex-direction: row;
`

const InfoContainer = styled.div`
    flex: 1;
    min-width: 0;
    min-height: 0;
    flex-direction: column;
    flex-wrap: wrap;
`
interface FileItemProps {
    file: File
    onRemoveClick: () => void
}

const FileItem = React.memo(({ file, onRemoveClick }: FileItemProps): JSX.Element => {
    const theme = useTheme()
    const { width } = useWindowSize()

    const isMobile = React.useMemo(() => {
        return width < theme.breakpoints.values.md
    }, [width, theme])

    const getFileSizeString = React.useCallback((file: File) => {
        const { size } = file
        if (size >= 1024 * 1024) {
            return `${Math.ceil(size / (1024 * 1024))}MB`
        } else if (size >= 1024) {
            return `${Math.ceil(size / 1024)}KB`
        } else {
            return `${Math.ceil(size)}B`
        }
    }, [])

    return isMobile ? (
        <FileRow>
            <IconButton onClick={onRemoveClick}>
                <Icon name="close" style={{ width: 22, height: 22 }} />
            </IconButton>
            <InfoContainer>
                <FileName>
                    <p>{file.name}</p>
                </FileName>
                <Tags>
                    {file.size > 1024 * 1024 ? (
                        <UploadFailure>Fallito</UploadFailure>
                    ) : (
                        <UploadSuccess>Caricato</UploadSuccess>
                    )}
                    <FileSize>{getFileSizeString(file)}</FileSize>
                </Tags>
            </InfoContainer>
        </FileRow>
    ) : (
        <FileRow>
            <Icon name="file" style={{ width: 22, height: 22 }} />
            <FileName>
                <p>{file.name}</p>
            </FileName>
            {file.size > 1024 * 1024 ? <UploadFailure>Fallito</UploadFailure> : <UploadSuccess>Caricato</UploadSuccess>}
            <FileSize>{getFileSizeString(file)}</FileSize>
            <IconButton onClick={onRemoveClick}>
                <Icon name="close" style={{ width: 22, height: 22 }} />
            </IconButton>
        </FileRow>
    )
})

export default FileItem
