import { call, takeLatest } from 'typed-redux-saga/macro'
import * as Storage from 'utils/local-storage'
import RootActionsIdentifiers from './root.consts'

function* cleanDataSaga(): Generator {
    yield call(Storage.del, 'api_key')
    yield call(Storage.del, 'user_id')
}

function* mySaga(): Generator {
    yield takeLatest(RootActionsIdentifiers.CLEAN_DATA, cleanDataSaga)
}

export default mySaga
