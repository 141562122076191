/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

import {
    Book,
    Checkmark,
    ChevronRight,
    Close,
    Edit,
    Email,
    Eye,
    EyeOff,
    File,
    Loader,
    Question,
    Sync,
    Upload,
    WeStudents,
} from './svg-icons'

const rendered =
    (Component: any) =>
    (props: CustomIconProps): JSX.Element =>
        <Component {...props} />

const icons = {
    book: { render: rendered(Book) },
    checkmark: { render: rendered(Checkmark) },
    chevronRight: { render: rendered(ChevronRight) },
    close: { render: rendered(Close) },
    edit: { render: rendered(Edit) },
    email: { render: rendered(Email) },
    eye: { render: rendered(Eye) },
    eyeOff: { render: rendered(EyeOff) },
    file: { render: rendered(File) },
    loader: { render: rendered(Loader) },
    question: { render: rendered(Question) },
    sync: { render: rendered(Sync) },
    upload: { render: rendered(Upload) },
    westudents: { render: rendered(WeStudents) },
}

export type IconName = keyof typeof icons

export default icons
