import Icon from 'components/atomics/atoms/icon'
import Input from 'components/atomics/atoms/input'
import { InputOption } from 'components/atomics/atoms/input/types'
import NextStepButton from 'components/atomics/molecules/next-step-button'
import StepProgressBar from 'components/atomics/molecules/step-progress-bar'
import PageLayout from 'components/atomics/organisms/page-layout'
import { useForm, useStoreListener, useTheme, useTranslation } from 'components/hooks'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { verifyElectronicRegister } from 'store/modules/student/student.actions'
import { StudentActions } from 'store/modules/student/student.consts'
import { studentLoadingSelector } from 'store/modules/student/student.selectors'
import styled from 'styled-components'
import createValidationSchema from 'utils/validation'

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    padding-top: 1rem;
`

const Header = styled.div`
    h2 {
        text-align: center;
    }

    h4 {
        text-align: center;
        color: ${({ theme }) => theme.palette.text.secondary};
    }

    p {
        text-align: center;
    }
`

const availableElectronicRegisters: InputOption[] = [
    {
        value: 'argo',
        label: 'Argo',
    },
    {
        value: 'axios',
        label: 'Axios',
    },
    {
        value: 'classeviva',
        label: 'ClasseViva',
    },
    {
        value: 'mastercom',
        label: 'Mastercom',
    },
    {
        value: 'nuvola',
        label: 'Nuvola',
    },
]

const ElectronicRegisterPage = (): JSX.Element => {
    const theme = useTheme()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [step, setStep] = React.useState<number>(1)

    const loading = useSelector(studentLoadingSelector)

    const form = useForm({
        initialValues: {
            er: '',
            schoolCode: '',
            userCode: '',
            password: '',
        },
        onSubmit: ({ er, userCode, schoolCode, password }) => {
            dispatch(
                verifyElectronicRegister({
                    electronic_register: er.toUpperCase(),
                    school_code: schoolCode?.length > 0 ? schoolCode : undefined,
                    username: userCode,
                    password,
                }),
            )
        },
        validateOnMount: true,
        validationSchema: createValidationSchema({
            er: {
                type: 'string',
                rules: {
                    required: { error: t('errors.required') },
                },
            },
            userCode: {
                type: 'string',
                rules: {
                    required: { error: t('errors.required') },
                },
            },
            password: {
                type: 'string',
                rules: {
                    required: { error: t('errors.required') },
                },
            },
        }),
    })

    const needSchoolCode = React.useMemo(() => {
        return (
            form.values.er === 'axios' ||
            form.values.er === 'argo' ||
            form.values.er === 'mastercom' ||
            form.values.er === 'nuvola'
        )
    }, [form.values.er])

    const isNextStepButtonEnabled = React.useMemo(() => {
        if (step === 2) {
            if (needSchoolCode) {
                return form.isValid && form.values.schoolCode !== undefined && form.values.schoolCode.length > 0
            }
            return form.isValid
        }
        return true
    }, [form.isValid, form.values.schoolCode, needSchoolCode, step])

    const handleSelectEr = React.useCallback(
        (event: any): void => {
            const { value } = event.target
            if (value === 'cta') {
                // todo: do something
                window.alert('richiedi registro')
            } else {
                form.handleChange('er')(value)
                setStep(2)
            }
        },
        [form],
    )

    const handleSubmitVerify = React.useCallback((): void => {
        form.handleSubmit()
    }, [form])

    const handleNextStep = React.useCallback((): void => {
        if (step < 2) {
            setStep((curr) => curr + 1)
        } else {
            handleSubmitVerify()
        }
    }, [handleSubmitVerify, step])

    useStoreListener({
        [StudentActions.VERIFY_ELECTRONIC_REGISTER_SUCCESS]: () => {
            setStep(3)
        },
    })

    const content = React.useMemo(() => {
        switch (step) {
            case 1:
                return (
                    <Content>
                        <Header>
                            <h2>{t('pages.ElectronicRegister.title')}</h2>
                            <p>{t('pages.ElectronicRegister.subtitle1')}</p>
                        </Header>
                        <Input
                            inputType="select"
                            label={t('pages.ElectronicRegister.erLabel')}
                            placeholder={t('pages.ElectronicRegister.erPlaceholder')}
                            options={availableElectronicRegisters}
                            value={form.values.er}
                            cta="Richiedi il tuo registro"
                            onCtaPress={(): void => console.log('todo')}
                            onChange={handleSelectEr}
                            onBlur={form.handleBlur('er')}
                        />
                    </Content>
                )
            case 2:
                return (
                    <Content>
                        <Header>
                            <h2>{t('pages.ElectronicRegister.title')}</h2>
                            <p>{t('pages.ElectronicRegister.subtitle2')}</p>
                        </Header>
                        <Input
                            inputType="select"
                            label={t('pages.ElectronicRegister.erLabel')}
                            placeholder={t('pages.ElectronicRegister.erPlaceholder')}
                            options={availableElectronicRegisters}
                            value={form.values.er}
                            onChange={handleSelectEr}
                            onBlur={form.handleBlur('er')}
                            cta="Richiedi il tuo registro"
                            onCtaPress={(): void => console.log('todo')}
                        />
                        {needSchoolCode ? (
                            <Input
                                label={t('pages.ElectronicRegister.schoolLabel')}
                                placeholder={t('pages.ElectronicRegister.schoolPlaceholder')}
                                iconRight="question"
                                value={form.values.schoolCode}
                                onChange={form.handleChange('schoolCode')}
                                onBlur={form.handleBlur('schoolCode')}
                                error={form.touched.schoolCode && form.errors.schoolCode !== undefined}
                                helperText={
                                    form.touched.schoolCode && form.errors.schoolCode !== undefined
                                        ? form.errors.schoolCode
                                        : undefined
                                }
                            />
                        ) : null}
                        <Input
                            label={t('pages.ElectronicRegister.userLabel')}
                            placeholder={t('pages.ElectronicRegister.userPlaceholder')}
                            iconRight="question"
                            value={form.values.userCode}
                            onChange={form.handleChange('userCode')}
                            onBlur={form.handleBlur('userCode')}
                            error={form.touched.userCode && form.errors.userCode !== undefined}
                            helperText={
                                form.touched.userCode && form.errors.userCode !== undefined
                                    ? form.errors.userCode
                                    : undefined
                            }
                        />
                        <Input
                            inputType="password"
                            label={t('pages.ElectronicRegister.passwordLabel')}
                            placeholder={t('pages.ElectronicRegister.passwordPlaceholder')}
                            value={form.values.password}
                            onChange={form.handleChange('password')}
                            onBlur={form.handleBlur('password')}
                            error={form.touched.password && form.errors.password !== undefined}
                            helperText={
                                form.touched.password && form.errors.password !== undefined
                                    ? form.errors.password
                                    : undefined
                            }
                        />
                    </Content>
                )
            case 3:
                return (
                    <Content style={{ alignItems: 'center', justifyContent: 'center', padding: '0 2rem' }}>
                        <Icon name="checkmark" fill={theme.palette.primary.main} style={{ height: 64, width: 64 }} />
                        <Header>
                            <h4>{t('pages.ElectronicRegister.verified')}</h4>
                        </Header>
                    </Content>
                )
        }
    }, [form, handleSelectEr, needSchoolCode, step, t, theme.palette.primary.main])

    return (
        <PageLayout>
            <Wrapper>
                <div style={{ flex: 1 }}>
                    <StepProgressBar current={step} max={3} />
                    {content}
                </div>
                <NextStepButton
                    onNextClick={handleNextStep}
                    enabled={isNextStepButtonEnabled}
                    hideButton={step === 1 || step === 3}
                    loading={loading}
                />
            </Wrapper>
        </PageLayout>
    )
}

export default ElectronicRegisterPage
