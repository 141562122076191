export const STATE_KEY = 'student'

export const initialState: StudentState = {
    ready: false,
    loading: false,
    initizialized: false,
    options: {
        wsLoginEnabled: true,
        electronicRegisterEnabled: true,
    },
}

export const StudentActions = {
    INIT: `${STATE_KEY}/INIT`,
    INIT_SUCCESS: `${STATE_KEY}/INIT_SUCCESS`,
    INIT_FAIL: `${STATE_KEY}/INIT_FAIL`,

    RESTORE: `${STATE_KEY}/RESTORE`,
    RESTORE_SUCCESS: `${STATE_KEY}/RESTORE_SUCCESS`,
    RESTORE_FAIL: `${STATE_KEY}/RESTORE_FAIL`,

    GET_SCHOOLS: `${STATE_KEY}/GET_SCHOOLS`,
    GET_SCHOOLS_SUCCESS: `${STATE_KEY}/GET_SCHOOLS_SUCCESS`,
    GET_SCHOOLS_FAIL: `${STATE_KEY}/GET_SCHOOLS_FAIL`,

    VERIFY_EMAIL: `${STATE_KEY}/VERIFY_EMAIL`,
    VERIFY_EMAIL_SUCCESS: `${STATE_KEY}/VERIFY_EMAIL_SUCCESS`,
    VERIFY_EMAIL_FAIL: `${STATE_KEY}/VERIFY_EMAIL_FAIL`,

    VERIFY_DOCUMENTS: `${STATE_KEY}/VERIFY_DOCUMENTS`,
    VERIFY_DOCUMENTS_SUCCESS: `${STATE_KEY}/VERIFY_DOCUMENTS_SUCCESS`,
    VERIFY_DOCUMENTS_FAIL: `${STATE_KEY}/VERIFY_DOCUMENTS_FAIL`,

    VERIFY_ELECTRONIC_REGISTER: `${STATE_KEY}/VERIFY_ELECTRONIC_REGISTER`,
    VERIFY_ELECTRONIC_REGISTER_SUCCESS: `${STATE_KEY}/VERIFY_ELECTRONIC_REGISTER_SUCCESS`,
    VERIFY_ELECTRONIC_REGISTER_FAIL: `${STATE_KEY}/VERIFY_ELECTRONIC_REGISTER_FAIL`,

    VERIFY_WS_TOKEN: `${STATE_KEY}/VERIFY_WS_TOKEN`,
    VERIFY_WS_TOKEN_SUCCESS: `${STATE_KEY}/VERIFY_WS_TOKEN_SUCCESS`,
    VERIFY_WS_TOKEN_FAIL: `${STATE_KEY}/VERIFY_WS_TOKEN_FAIL`,
}
