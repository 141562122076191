import * as React from 'react'

import { SerializedStyles } from '@emotion/serialize'
import { MoonLoader } from 'react-spinners'

interface ILoader {
    size?: number | string
    css?: SerializedStyles
    color?: string
}

const Loader = React.memo(({ size, css, color }: ILoader) => {
    return <MoonLoader css={css} size={size || 22} color={color || '#fff'} loading />
})

export default Loader
