import { ActionWithPayload } from 'store/types'
import { call, put, takeEvery, takeLatest } from 'typed-redux-saga/macro'
import wsSdk from 'utils/ws-sdk'
import WsSdkError from 'utils/ws-sdk/utils/ws-sdk-error'
import { StudentActions } from './student.consts'
import * as Storage from 'utils/local-storage'

function* restoreSaga(): Generator {
    try {
        const apiKey = (yield call(Storage.get, 'api_key')) as string | null
        const userId = (yield call(Storage.get, 'user_id')) as string | null
        if (apiKey) yield call(wsSdk.setAuthData, { apiKey, userId: userId || undefined })

        yield put({ type: StudentActions.RESTORE_SUCCESS, payload: { userId } })
    } catch (error) {
        yield put({ type: StudentActions.RESTORE_FAIL, payload: error })
    }
}

function* initSaga(action: ActionWithPayload<InitProps>): Generator {
    const { apiKey, userId, options } = action.payload
    try {
        yield call(wsSdk.setAuthData, { apiKey, userId: userId || undefined })

        let requestStatus

        yield call(Storage.set, 'api_key', apiKey)
        if (userId) {
            yield call(Storage.set, 'user_id', userId)
            try {
                const { data } = (yield call(wsSdk.getRequestStatusWithUserId, userId)) as RequestStatusResponse
                requestStatus = data
            } catch (error) {
                if ((error as WsSdkError).data.code !== 'VERIFICATION_REQUEST_NOT_FOUND') throw error
            }
        }

        yield put({ type: StudentActions.INIT_SUCCESS, payload: { ...requestStatus, options } })
    } catch (error) {
        yield put({ type: StudentActions.INIT_FAIL, payload: error })
    }
}

function* getSchoolsSaga(action: ActionWithPayload<{ name: string }>): Generator {
    const {
        payload: { name },
    } = action

    try {
        const { data } = (yield call(wsSdk.getSchools, { name })) as PaginatedApiResponse<School>
        yield put({ type: StudentActions.GET_SCHOOLS_SUCCESS, payload: data.results })
    } catch (error) {
        yield put({ type: StudentActions.GET_SCHOOLS_FAIL, payload: error })
    }
}

function* verifyEmailSaga(action: ActionWithPayload<VerifyEmailProps>): Generator {
    const { payload } = action
    try {
        const { data } = (yield call(wsSdk.verifyEmail, payload)) as VerifyResponse
        yield put({ type: StudentActions.VERIFY_EMAIL_SUCCESS, payload: data })
    } catch (error) {
        yield put({ type: StudentActions.VERIFY_EMAIL_FAIL, payload: error })
    }
}

function* verifyDocumentsSaga(action: ActionWithPayload<VerifyDocumentsProps>): Generator {
    const { payload } = action
    try {
        const { data } = (yield call(wsSdk.verifyDocuments, payload)) as VerifyResponse
        yield put({ type: StudentActions.VERIFY_DOCUMENTS_SUCCESS, payload: data })
    } catch (error) {
        yield put({ type: StudentActions.VERIFY_DOCUMENTS_FAIL, payload: error })
    }
}

function* verifyElectronicRegisterSaga(action: ActionWithPayload<VerifyElectronicRegisterProps>): Generator {
    const { payload } = action
    try {
        const { data } = (yield call(wsSdk.verifyElectronicRegister, payload)) as VerifyResponse
        yield put({ type: StudentActions.VERIFY_ELECTRONIC_REGISTER_SUCCESS, payload: data })
    } catch (error) {
        yield put({ type: StudentActions.VERIFY_ELECTRONIC_REGISTER_FAIL, payload: error })
    }
}

function* verifyWSTokenSaga(action: ActionWithPayload<VerifyWSTokenProps>): Generator {
    const { payload } = action
    try {
        const { data } = (yield call(wsSdk.verifyWSToken, payload)) as VerifyResponse
        yield put({ type: StudentActions.VERIFY_WS_TOKEN_SUCCESS, payload: data })
    } catch (error) {
        yield put({ type: StudentActions.VERIFY_WS_TOKEN_FAIL, payload: error })
    }
}

function* sagas(): Generator {
    yield takeLatest(StudentActions.RESTORE, restoreSaga)
    yield takeLatest(StudentActions.INIT, initSaga)
    yield takeEvery(StudentActions.GET_SCHOOLS, getSchoolsSaga)
    yield takeLatest(StudentActions.VERIFY_EMAIL, verifyEmailSaga)
    yield takeLatest(StudentActions.VERIFY_WS_TOKEN, verifyWSTokenSaga)
    yield takeLatest(StudentActions.VERIFY_DOCUMENTS, verifyDocumentsSaga)
    yield takeLatest(StudentActions.VERIFY_ELECTRONIC_REGISTER, verifyElectronicRegisterSaga)
}

export default sagas
