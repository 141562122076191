import { useTheme } from 'components/hooks'
import * as React from 'react'
import styled from 'styled-components'
import Loader from '../loader'

interface ButtonProps {
    children: string
    disabled?: boolean
    loading?: boolean
    onClick: () => void
}

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0.7rem 0;
    margin: 1rem 0;
    cursor: pointer;
    min-height: 1.8rem;

    &:hover {
        background: ${({ theme }) => theme.palette.primary[900]};
    }
`
const ButtonText = styled.p`
    font-weight: 700;
    font-size: 1.2rem;
    margin: 0;
    color: #fff;
`

const Button = ({ children, disabled = false, loading = false, onClick }: ButtonProps): JSX.Element => {
    const theme = useTheme()

    return (
        <ButtonWrapper
            onClick={onClick}
            style={{
                // @ts-ignore
                background: disabled ? theme.palette.primary[200] : theme.palette.primary[500],
                pointerEvents: loading || disabled ? 'none' : 'auto',
            }}>
            {loading ? <Loader /> : <ButtonText>{children}</ButtonText>}
        </ButtonWrapper>
    )
}

export default Button
