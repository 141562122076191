import { useTheme } from 'components/hooks'

function Checkmark({ fill, style, ...props }: CustomIconProps) {
    const theme = useTheme()
    return (
        <div style={style} {...props}>
            <svg fill="none" viewBox="0 0 80 80" {...props}>
                <path
                    d="M40 6.667a33.333 33.333 0 1 0 0 66.666 33.333 33.333 0 0 0 0-66.666Zm0 60a26.668 26.668 0 1 1 0-53.336 26.668 26.668 0 0 1 0 53.335Z"
                    fill={fill || theme.palette.text.primary}
                />
                <path
                    d="M49 27.967 36.4 44.633 30.967 37.6a3.337 3.337 0 1 0-5.267 4.1l8.1 10.367a3.334 3.334 0 0 0 2.633 1.266 3.335 3.335 0 0 0 2.634-1.3l15.233-20a3.353 3.353 0 1 0-5.333-4.066H49Z"
                    fill={fill || theme.palette.text.primary}
                />
            </svg>
        </div>
    )
}

export default Checkmark
