import { useTheme, useWindowSize } from 'components/hooks'
import _ from 'lodash'
import * as React from 'react'

import customIcons from './components/custom-icons'
import { IconProps } from './types'

const defaultStyle = {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const mobileStyle = {
    width: 32,
    height: 32,
    display: 'flex',
}

export const Icon = ({ style = {}, name, fill }: IconProps): JSX.Element => {
    const theme = useTheme()
    const { width } = useWindowSize()
    const isMobile = React.useMemo(() => {
        return width < theme.breakpoints.values.md
    }, [theme.breakpoints.values.md, width])

    const mergedStyles = _.merge(
        {},
        isMobile ? mobileStyle : defaultStyle,
        Array.isArray(style)
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              style.reduce((acc: React.CSSProperties, item: any) => {
                  return { ...acc, ...item }
              }, {})
            : style,
    )

    return customIcons[name as keyof typeof customIcons].render({ style: mergedStyles, fill })
}

export default Icon
