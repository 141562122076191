import ErrorComponent from 'components/atomics/atoms/error'
import * as React from 'react'
import styled from 'styled-components'

const Page = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const NotFoundPage = (): JSX.Element => {
    return (
        <Page>
            <ErrorComponent />
        </Page>
    )
}

export default NotFoundPage
