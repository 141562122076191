import { MenuItem } from '@mui/material'
import { useTheme } from 'components/hooks'
import * as React from 'react'
import { SelectInputProps } from '../../types'
import BaseInput from '../base'

const SelectInput = ({ options, cta, onCtaPress, ...props }: SelectInputProps): JSX.Element => {
    const theme = useTheme()

    return (
        <BaseInput {...props} select>
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
            {cta !== undefined ? (
                <MenuItem
                    key={`cta_${cta}`}
                    value="cta"
                    style={{ color: theme.palette.primary.main, fontWeight: '600' }}
                    onClick={onCtaPress}>
                    {cta}
                </MenuItem>
            ) : null}
        </BaseInput>
    )
}

export default SelectInput
