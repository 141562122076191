import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { ActionWithPayload } from 'store/types'
import getTypedReducerMap from 'utils/redux/get-typed-reducer-map'
import WsSdkError from 'utils/ws-sdk/utils/ws-sdk-error'
import { initialState, STATE_KEY, StudentActions } from './student.consts'

const reducer = createReducer<StudentState>(initialState, (builder) => {
    builder
        .addCase(StudentActions.RESTORE_SUCCESS, (state, action: PayloadAction<{ userId: string }>) => {
            const { userId } = action.payload
            state.userId = userId
            state.ready = true
        })
        .addCase(StudentActions.RESTORE_FAIL, (state, action: PayloadAction<WsSdkError>) => {
            state.error = action.payload
            state.ready = true
        })

        .addCase(StudentActions.INIT, (state, action: ActionWithPayload<InitProps>) => {
            const {
                payload: { userId },
            } = action
            state.userId = userId || undefined
            state.loading = true
        })
        .addCase(
            StudentActions.INIT_SUCCESS,
            (
                state,
                action: PayloadAction<{ verified: boolean; requests: VerifyRequest[]; options: ConfigOptions | null }>,
            ) => {
                const {
                    payload: { verified, requests, options },
                } = action
                state.verified = verified
                state.requests = requests
                state.loading = false
                state.initizialized = true
                if (options !== null) state.options = options
            },
        )
        .addCase(StudentActions.INIT_FAIL, (state, action: PayloadAction<WsSdkError>) => {
            state.error = action.payload
            state.loading = false
        })

        .addCase(StudentActions.GET_SCHOOLS_SUCCESS, (state, action: PayloadAction<School[]>) => {
            const { payload: schools } = action
            state.schools = schools
        })
        .addCase(StudentActions.GET_SCHOOLS_FAIL, (state, action: PayloadAction<WsSdkError>) => {
            state.error = action.payload
        })

        .addCase(StudentActions.VERIFY_EMAIL, (state) => {
            state.loading = true
        })
        .addCase(StudentActions.VERIFY_EMAIL_SUCCESS, (state, action: PayloadAction<VerifyRequestCreated>) => {
            const {
                payload: { user_id, verified, status, type },
            } = action
            state.userId = user_id
            state.verified = verified
            state.requests = [
                ...(state.requests || []),
                {
                    status,
                    verified,
                    type,
                },
            ]
            state.loading = false
        })
        .addCase(StudentActions.VERIFY_EMAIL_FAIL, (state, action: PayloadAction<WsSdkError>) => {
            state.error = action.payload
            state.loading = false
        })

        .addCase(StudentActions.VERIFY_DOCUMENTS, (state) => {
            state.loading = true
        })
        .addCase(StudentActions.VERIFY_DOCUMENTS_SUCCESS, (state, action: PayloadAction<VerifyRequestCreated>) => {
            const {
                payload: { user_id, verified, status, type },
            } = action
            state.userId = user_id
            state.verified = verified
            state.requests = [
                ...(state.requests || []),
                {
                    status,
                    verified,
                    type,
                },
            ]
            state.loading = false
        })
        .addCase(StudentActions.VERIFY_DOCUMENTS_FAIL, (state, action: PayloadAction<WsSdkError>) => {
            state.error = action.payload
            state.loading = false
        })

        .addCase(StudentActions.VERIFY_ELECTRONIC_REGISTER, (state) => {
            state.loading = true
        })
        .addCase(
            StudentActions.VERIFY_ELECTRONIC_REGISTER_SUCCESS,
            (state, action: PayloadAction<VerifyRequestCreated>) => {
                const {
                    payload: { user_id, verified, status, type },
                } = action
                state.verified = verified
                state.userId = user_id
                state.requests = [
                    ...(state.requests || []),
                    {
                        status,
                        verified,
                        type,
                    },
                ]
                state.loading = false
            },
        )
        .addCase(StudentActions.VERIFY_ELECTRONIC_REGISTER_FAIL, (state, action: PayloadAction<WsSdkError>) => {
            state.error = action.payload
            state.loading = false
        })

        .addCase(StudentActions.VERIFY_WS_TOKEN, (state) => {
            state.loading = false
        })

        .addCase(StudentActions.VERIFY_WS_TOKEN_SUCCESS, (state, action: PayloadAction<{ verified: boolean }>) => {
            const {
                payload: { verified },
            } = action
            state.verified = verified
            state.loading = false
        })
        .addCase(StudentActions.VERIFY_WS_TOKEN_FAIL, (state, action: PayloadAction<WsSdkError>) => {
            state.error = action.payload
            state.loading = false
        })
})

export const reducerMap = getTypedReducerMap<StudentState>({
    [STATE_KEY]: reducer,
})

export default reducer
