import axios from 'axios'
import { getEnv } from 'utils/env'
import Api from './api'

import { handleError } from './utils/ws-sdk-error'

interface WsSdkProps {
    apiUrl: string
}

const { API_BASE_URL } = getEnv()

export class WsSDK {
    private static instance: WsSDK
    private api: Api | undefined
    private authData?: WsAuthData

    constructor({ apiUrl }: WsSdkProps) {
        if (!WsSDK.instance) {
            WsSDK.instance = this
        }

        WsSDK.instance.api = new Api({ apiUrl })

        return WsSDK.instance
    }

    public static getInstance(): WsSDK {
        if (WsSDK.instance === null || !(WsSDK.instance instanceof WsSDK)) {
            throw new Error('WS SDK not initialized yet')
        }
        return WsSDK.instance
    }

    public setAuthData(authData: WsAuthData): void {
        WsSDK.instance.api?.setAuthData(authData)
        WsSDK.instance.authData = authData
    }

    public async getSchools(params: GetSchoolsProps): Promise<PaginatedApiResponse<School>> {
        try {
            const resp = await WsSDK.instance.api?.call({
                url: `schools`,
                params: params as { [key: string]: string },
            })
            return resp?.data
        } catch (error) {
            throw handleError('ERR_GET_SHOOLS', error)
        }
    }

    // public async getRequestStatus(requestId: string): Promise<RequestStatusResponse> {
    //     try {
    //         const resp = await WsSDK.instance.api?.call({
    //             url: `identity/verification/request/${requestId}`,
    //         })
    //         return resp?.data
    //     } catch (error) {
    //         throw handleError('ERR_REQUEST_STATUS', error)
    //     }
    // }

    // public async getExternalIdRequestStatus(externalId: string): Promise<RequestStatusResponse> {
    //     try {
    //         const resp = await WsSDK.instance.api?.call({
    //             url: `identity/verification/student/${externalId}`,
    //         })
    //         return resp?.data
    //     } catch (error) {
    //         throw handleError('ERR_EXTERNAL_ID_REQUEST_STATUS', error)
    //     }
    // }

    public async getRequestStatusWithUserId(userId: string): Promise<RequestStatusResponse> {
        try {
            const resp = await WsSDK.instance.api?.call({
                url: `identity/verification/request/${userId}`,
            })
            return resp?.data
        } catch (error) {
            throw handleError('ERR_GET_REQUEST_STATUS_WITH_USER_ID', error)
        }
    }

    public async verifyEmail(data: VerifyEmailProps): Promise<VerifyResponse> {
        const { userId: user_id } = WsSDK.instance.authData || { userId: undefined }
        try {
            const resp = await WsSDK.instance.api?.call({
                method: 'POST',
                url: 'identity/email',
                data: {
                    ...data,
                    external_id: user_id,
                },
            })
            return resp?.data
        } catch (error) {
            throw handleError('ERR_VERIFY_EMAIL', error)
        }
    }

    public async verifyElectronicRegister(data: VerifyElectronicRegisterProps): Promise<VerifyResponse> {
        const { userId: user_id } = WsSDK.instance.authData || { userId: undefined }
        try {
            const resp = await WsSDK.instance.api?.call({
                method: 'POST',
                url: 'identity/electronic-register',
                data: {
                    ...data,
                    external_id: user_id,
                },
            })
            return resp?.data
        } catch (error) {
            throw handleError('ERR_VERIFY_ELECTRONIC_REGISTER', error)
        }
    }

    public async verifyDocuments({ files, email, school_code }: VerifyDocumentsProps): Promise<VerifyResponse> {
        const { userId: user_id } = WsSDK.instance.authData || { userId: undefined }
        try {
            let formData = new FormData()
            files.forEach((file) => {
                console.log('ooooo', file)
                formData.append('documents', file, file.name)
            })
            formData.append('email', email)
            formData.append('school_code', school_code)
            if (user_id) formData.append('external_id', user_id)

            const resp = await WsSDK.instance.api?.call({
                method: 'POST',
                url: 'identity/documents',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return resp?.data
        } catch (error) {
            throw handleError('ERR_VERIFY_DOCUMENTS', error)
        }
    }

    public async verifyWSToken({ token, userId }: VerifyWSTokenProps): Promise<VerifyResponse> {
        try {
            const resp = await WsSDK.instance.api?.call({
                method: 'POST',
                url: 'identity/westudents',
                data: {
                    token,
                    external_id: userId,
                },
            })
            return resp?.data
        } catch (error) {
            throw handleError('ERR_VERIFY_WS_TOKEN', error)
        }
    }
}

const wsSdk = new WsSDK({ apiUrl: API_BASE_URL || '' })

export default wsSdk
