import { createAction } from '@reduxjs/toolkit'
import RootActions from './root.consts'

export const clean = createAction<string>(RootActions.CLEAN)
export const cleanData = createAction(RootActions.CLEAN_DATA)

const rootActions = {
    clean,
    cleanData,
}

export default rootActions
