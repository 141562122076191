import * as localforage from 'localforage'
import { getEnv } from 'utils/env'

const { INDEXED_DB_NAME, INDEXED_DB_STORE_NAME, INDEXED_DB_VERSION, INDEXED_DB_STORE_DESCRIPTION } = getEnv()

localforage.config({
    driver: localforage.INDEXEDDB,
    name: INDEXED_DB_NAME || '',
    version: parseFloat(INDEXED_DB_VERSION || ''),
    storeName: INDEXED_DB_STORE_NAME || '',
    description: INDEXED_DB_STORE_DESCRIPTION || '',
})
