import { createAction } from '@reduxjs/toolkit'
import { StudentActions } from './student.consts'

export const init = createAction<InitProps>(StudentActions.INIT)
export const restore = createAction(StudentActions.RESTORE)
export const getSchools = createAction<{ name: string }>(StudentActions.GET_SCHOOLS)
export const verifyEmail = createAction<VerifyEmailProps>(StudentActions.VERIFY_EMAIL)
export const verifyDocuments = createAction<VerifyDocumentsProps>(StudentActions.VERIFY_DOCUMENTS)
export const verifyElectronicRegister = createAction<VerifyElectronicRegisterProps>(
    StudentActions.VERIFY_ELECTRONIC_REGISTER,
)
export const verifyWSToken = createAction<VerifyWSTokenProps>(StudentActions.VERIFY_WS_TOKEN)
