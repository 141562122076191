import Icon from 'components/atomics/atoms/icon'
import * as React from 'react'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import styled from 'styled-components'
import FileItem from './components/file-item'

const Container = styled.div`
    border: 1px dashed ${({ theme }) => theme.palette.divider};
    border-radius: 1rem;
    flex: 1;
    display: flex;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        padding: 0.3rem;
    }
`

const EmptyWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const FilesWrapper = styled.div`
    padding: 1rem;
    flex: 1;
    width: 100%;
    flex-wrap: nowrap;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`

const Subtitle = styled.div`
    font-size: 12px;
    margin: 0;
    color: ${({ theme }) => theme.palette.text.secondary};
    text-align: center;
`

interface DragAndDropProps {
    files?: File[]
    updateFiles: (files: File[]) => void
    removeFile: (index: number) => void
}
const DragAndDrop = ({ files, updateFiles, removeFile }: DragAndDropProps): JSX.Element => {
    const dropzoneRef = React.createRef<DropzoneRef>()

    const onDrop = React.useCallback(
        (acceptedFiles: File[]) => {
            // todo:
            updateFiles(acceptedFiles)
            // setFiles((curr) => [...(files || []), ...acceptedFiles])
        },
        [updateFiles],
    )

    const handleOpenFileDialog = (): void => {
        dropzoneRef.current?.open()
    }

    const handleRemoveFile = React.useCallback(
        (index: number) => {
            removeFile(index)
        },
        [removeFile],
    )

    const renderFileItem = React.useCallback(
        (file: File, index: number) => {
            return (
                <FileItem
                    key={`${index}_${file.name}`}
                    file={file}
                    onRemoveClick={(): void => handleRemoveFile(index)}
                />
            )
        },
        [handleRemoveFile],
    )

    return (
        <Dropzone
            ref={dropzoneRef}
            onDrop={onDrop}
            noClick
            accept={{
                'image/png': ['.png'],
                'image/jpeg': ['.jpg', '.jpeg'],
                'application/pdf': ['.pdf'],
            }}
            maxFiles={4}
            maxSize={40 * 1024 * 1024} // 40 MB
        >
            {({ getRootProps, getInputProps, isDragActive }) => (
                <Container
                    {...getRootProps()}
                    style={{ background: isDragActive ? 'rgba(186, 186, 186, 0.3)' : undefined }}>
                    <input {...getInputProps()} />
                    {files === undefined || files.length === 0 ? (
                        <EmptyWrapper>
                            <Icon name="upload" />
                            <p style={{ margin: 0, textAlign: 'center' }}>
                                <span className="link" onClick={handleOpenFileDialog}>
                                    Carica
                                </span>{' '}
                                o trascina qui i file da verificare
                            </p>
                            <Subtitle>(file jpg, png e pdf. Dimensioni max per file 40mb)</Subtitle>
                        </EmptyWrapper>
                    ) : (
                        <FilesWrapper>{files.map(renderFileItem)}</FilesWrapper>
                    )}
                </Container>
            )}
        </Dropzone>
    )
}

export default DragAndDrop
