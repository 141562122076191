import { createTheme, Theme as MuiTheme } from '@mui/material/styles'

const theme = createTheme({
    palette: {
        primary: {
            '100': '#FFEBE0',
            '200': '#FFD8C1',
            '300': '#FFC29C',
            '500': '#FF9D64',
            '900': '#CF7845',
            contrastText: '#fff',
        },
        secondary: {
            main: '#1976d2',
        },
        error: {
            '500': '#FF7575',
        },
        warning: {
            '500': '#FFBA00',
        },
        background: {
            default: '#FFFFFF',
            paper: '#FFFFFF',
        },
        text: {
            primary: '#898889',
            secondary: '#ABABAB',
        },
        success: {
            '500': '#79BD94',
        },
        divider: '#BABABA',
    },
})

export default theme

export type Theme = MuiTheme
