import { PayloadAction } from '@reduxjs/toolkit'
import { IExtension } from 'redux-dynamic-modules'
import WsSdkError from 'utils/ws-sdk/utils/ws-sdk-error'

interface Action {
    type: string
}

const emitter =
    () =>
    (next: (action: Action) => void) =>
    (action: PayloadAction<unknown>): unknown => {
        if (process.env.NODE_ENV === 'development' && !process.env.JEST_WORKER_ID && action.type.includes('_FAIL')) {
            console.error(action.payload)
            if (action.payload instanceof WsSdkError) {
                console.error(action.payload.data)
            }
        }

        return next(action)
    }

const getErrorHandlerExtension = (): IExtension => {
    return {
        middleware: [emitter],
    }
}

export default getErrorHandlerExtension
