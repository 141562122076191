import * as React from 'react'
import { InputProps } from '../../types'
import BaseInput from '../base'

const PasswordInput = (props: InputProps): JSX.Element => {
    const [showPassword, setShowPassword] = React.useState<boolean>(false)

    return (
        <BaseInput
            {...props}
            type={showPassword ? 'text' : 'password'}
            iconRight={showPassword ? 'eyeOff' : 'eye'}
            onIconRightClick={(): void => setShowPassword((curr) => !curr)}
        />
    )
}

export default PasswordInput
