import Button from 'components/atomics/atoms/button'
import { useTranslation } from 'components/hooks'
import * as React from 'react'
import { Link } from 'react-router-dom'

interface NextStepButtonProps {
    buttonText?: string
    enabled?: boolean
    hideButton?: boolean
    loading?: boolean
    onNextClick: () => void
}

const NextStepButton = ({
    buttonText,
    onNextClick,
    loading = false,
    enabled = true,
    hideButton = false,
}: NextStepButtonProps): JSX.Element => {
    const { t } = useTranslation()
    return (
        <div>
            {!hideButton ? (
                <Button onClick={onNextClick} disabled={!enabled} loading={loading}>
                    {buttonText || t('buttons.continue')}
                </Button>
            ) : null}
            <Link to="/methods">
                <p style={{ textAlign: 'center', marginBottom: 0 }}>{t('labels.tryOtherMethod')}</p>
            </Link>
        </div>
    )
}

export default NextStepButton
