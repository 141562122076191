import { useTheme } from 'components/hooks'

function ChevronRight({ fill, style, ...props }: CustomIconProps) {
    const theme = useTheme()
    return (
        <div style={style} {...props}>
            <svg fill="none" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M17.5 28.333a1.668 1.668 0 0 1-1.548-2.307c.085-.203.208-.388.365-.543L21.833 20l-5.3-5.517a1.667 1.667 0 1 1 2.367-2.35l6.433 6.667a1.667 1.667 0 0 1 0 2.333L18.667 27.8a1.666 1.666 0 0 1-1.167.533Z"
                    fill={fill || theme.palette.text.primary}
                />
            </svg>
        </div>
    )
}

export default ChevronRight
