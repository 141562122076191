import { useTheme } from 'components/hooks'

function Close({ fill, style, ...props }: CustomIconProps) {
    const theme = useTheme()
    return (
        <div style={style} {...props}>
            <svg fill="none" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M12.5 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16.001A8 8 0 0 1 12.5 20Z"
                    fill={fill || theme.palette.text.primary}
                />
                <path
                    d="M15.21 9.29a.999.999 0 0 0-1.42 0l-1.29 1.3-1.29-1.3a1.004 1.004 0 0 0-1.42 1.42l1.3 1.29-1.3 1.29a1 1 0 0 0 0 1.42.998.998 0 0 0 1.42 0l1.29-1.3 1.29 1.3a.998.998 0 0 0 1.42 0 .997.997 0 0 0 .219-1.095.998.998 0 0 0-.22-.325L13.91 12l1.3-1.29a.997.997 0 0 0 .22-1.095.998.998 0 0 0-.22-.325Z"
                    fill={fill || theme.palette.text.primary}
                />
            </svg>
        </div>
    )
}

export default Close
