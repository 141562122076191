import Icon from 'components/atomics/atoms/icon'
import { useTranslation } from 'components/hooks'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'
import { getEnv } from 'utils/env'

const ItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.3rem 0;
    cursor: pointer;

    p {
        text-align: left !important;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        padding: 0.4rem 0;
    }
`

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    flex: 1;
    justify-content: center;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        margin-left: 1rem;
    }
`

const Description = styled.p`
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: 200;
    margin: 0.4rem 0;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        margin: 0;
    }
`

export type Method = 'email' | 'docs' | 'electronic-register' | 'wslogin'

interface MethodItemProps {
    method: Method
}

const scope = 'email'
const { WS_LOGIN_CLIENT_ID, WS_LOGIN_REDIRECT_URI } = getEnv()

const MethodItem = ({ method }: MethodItemProps): JSX.Element => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const icon = React.useMemo(() => {
        switch (method) {
            case 'docs':
                return 'file'
            case 'electronic-register':
                return 'book'
            case 'email':
                return 'email'
            case 'wslogin':
                return 'westudents'
        }
    }, [method])

    const handleOnClick = React.useCallback(() => {
        switch (method) {
            case 'docs':
                navigate('/documents')
                break
            case 'electronic-register':
                navigate('/electronic-register')
                break
            case 'email':
                navigate('/email')
                break
            case 'wslogin': // todo: update when ws login is completed
                window.open(
                    `https://login-with-we.netlify.app?clientId=${WS_LOGIN_CLIENT_ID}&scope=${scope}&redirectUri=${WS_LOGIN_REDIRECT_URI}`,
                    'popUpWindowLogin',
                    'height=500,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes',
                )
        }
    }, [method, navigate])

    return (
        <ItemWrapper onClick={handleOnClick}>
            <Icon name={icon} />
            <InfoWrapper>
                <h3>{t(`pages.SelectMethod.methods.${method}.title`)}</h3>
                <Description>{t(`pages.SelectMethod.methods.${method}.desc`)}</Description>
            </InfoWrapper>
            <Icon name="chevronRight" />
        </ItemWrapper>
    )
}

export default MethodItem
