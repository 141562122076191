import { useTheme } from 'components/hooks'

function Upload({ fill, style, ...props }: CustomIconProps) {
    const theme = useTheme()
    return (
        <div style={style} {...props}>
            <svg fill="none" {...props} viewBox="0 0 48 48">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 6c5.162 0 9.71 3.322 11.35 8.09C40.227 14.752 44 18.944 44 24a9.99 9.99 0 0 1-2.5 6.618 2.001 2.001 0 1 1-2.998-2.65A5.987 5.987 0 0 0 39.998 24c0-3.308-2.692-6-6-6h-.2a2 2 0 0 1-1.96-1.606C31.093 12.69 27.797 10 24 10c-3.793 0-7.091 2.69-7.837 6.394A2.002 2.002 0 0 1 14.2 18H14c-3.309 0-6 2.692-6 6 0 1.464.532 2.876 1.5 3.968a2 2 0 1 1-2.998 2.65A9.983 9.983 0 0 1 4 24c0-5.056 3.772-9.248 8.65-9.91C14.29 9.322 18.84 6 24 6Zm-1.39 16.56a2.005 2.005 0 0 1 2.804.026l6 6A1.998 1.998 0 0 1 30 32a1.995 1.995 0 0 1-1.414-.586L26 28.828V40a2 2 0 1 1-4 0V28.712l-2.61 2.52c-.794.77-2.06.744-2.828-.05a2 2 0 0 1 .048-2.828l6-5.794Z"
                    fill={fill || theme.palette.text.primary}
                />
                <mask
                    id="a"
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits="userSpaceOnUse"
                    x={3}
                    y={5}
                    width={41}
                    height={37}>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24 6c5.162 0 9.71 3.322 11.35 8.09C40.227 14.752 44 18.944 44 24a9.99 9.99 0 0 1-2.5 6.618 2.001 2.001 0 1 1-2.998-2.65A5.987 5.987 0 0 0 39.998 24c0-3.308-2.692-6-6-6h-.2a2 2 0 0 1-1.96-1.606C31.093 12.69 27.797 10 24 10c-3.793 0-7.091 2.69-7.837 6.394A2.002 2.002 0 0 1 14.2 18H14c-3.309 0-6 2.692-6 6 0 1.464.532 2.876 1.5 3.968a2 2 0 1 1-2.998 2.65A9.983 9.983 0 0 1 4 24c0-5.056 3.772-9.248 8.65-9.91C14.29 9.322 18.84 6 24 6Zm-1.39 16.56a2.005 2.005 0 0 1 2.804.026l6 6A1.998 1.998 0 0 1 30 32a1.995 1.995 0 0 1-1.414-.586L26 28.828V40a2 2 0 1 1-4 0V28.712l-2.61 2.52c-.794.77-2.06.744-2.828-.05a2 2 0 0 1 .048-2.828l6-5.794Z"
                        fill="#fff"
                    />
                </mask>
                <g mask="url(#a)">
                    <path fill={fill || theme.palette.text.primary} d="M0 0h48v48H0z" />
                </g>
            </svg>
        </div>
    )
}

export default Upload
