import axios, { AxiosInstance, AxiosResponse, Method } from 'axios'

interface ApiCallProps {
    method?: Method
    // eslint-disable-next-line @typescript-eslint/ban-types
    data?: object | string
    url: string
    baseURL?: string
    headers?: { [key: string]: string }
    params?: { [key: string]: string }
}

export default class Api {
    private static _instance: Api | null = null
    private api!: AxiosInstance
    private authData?: WsAuthData

    public static getInstance(): Api {
        if (!(Api._instance instanceof Api)) {
            throw new Error('Api not initialized yet')
        }

        return Api._instance
    }

    public static destroyInstance(): void {
        Api._instance?.destroy()
        Api._instance = null
    }

    constructor({ apiUrl }: { apiUrl: string }) {
        if (Api._instance) {
            return Api._instance
        }

        Api._instance = this
        Api._instance.api = axios.create({
            baseURL: apiUrl,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            },
        })

        return Api._instance
    }

    public setAuthData(authData: WsAuthData): void {
        if (Api._instance) {
            Api._instance.authData = authData
            Api._instance.api.defaults.headers.common['Authorization'] = `Api-Key ${authData.apiKey}`
        } else {
            throw new Error('Api not initialized')
        }
    }

    private _call({
        method = 'get',
        data,
        url,
        baseURL,
        headers,
        params,
    }: ApiCallProps): Promise<AxiosResponse> | undefined {
        return Api._instance?.api.request({
            method: method || 'get',
            data,
            url,
            baseURL,
            headers,
            params,
        })
    }

    public async call(props: ApiCallProps): Promise<AxiosResponse> {
        const resp = await Api._instance?._call(props)
        if (resp !== undefined) {
            return resp
        } else {
            throw new Error('Api error')
        }
    }

    public destroy(): void {
        this.destroy()
    }
}
