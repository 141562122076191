import { useTheme } from 'components/hooks'

function Loader({ fill, style, ...props }: CustomIconProps) {
    const theme = useTheme()
    return (
        <div style={style} {...props}>
            <svg fill="none" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5 3a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0V3Zm8 8h2a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2Zm-13 1a1 1 0 0 0-1-1h-2a1 1 0 1 0 0 2h2a1 1 0 0 0 1-1ZM5.307 5.053a1 1 0 0 1 1.414-.025l1.439 1.39a1 1 0 0 1-1.39 1.44l-1.44-1.39a1 1 0 0 1-.023-1.415Zm12.228 3.085a1 1 0 0 0 .695-.28l1.44-1.39a1 1 0 0 0-1.39-1.44l-1.44 1.39a1 1 0 0 0 .695 1.72ZM11.5 19a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0v-2Zm6.73-2.857a1 1 0 0 0-1.39 1.439l1.439 1.39c.195.188.445.28.695.28a1 1 0 0 0 .695-1.719l-1.439-1.39Zm-12.899 1.39 1.439-1.39a1 1 0 0 1 1.39 1.439l-1.439 1.39a.996.996 0 0 1-.695.28 1 1 0 0 1-.695-1.719Z"
                    fill={fill || theme.palette.text.primary}
                />
                <mask
                    id="a"
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits="userSpaceOnUse"
                    x={2}
                    y={2}
                    width={21}
                    height={20}>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.5 3a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0V3Zm8 8h2a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2Zm-13 1a1 1 0 0 0-1-1h-2a1 1 0 1 0 0 2h2a1 1 0 0 0 1-1ZM5.307 5.053a1 1 0 0 1 1.414-.025l1.439 1.39a1 1 0 0 1-1.39 1.44l-1.44-1.39a1 1 0 0 1-.023-1.415Zm12.228 3.085a1 1 0 0 0 .695-.28l1.44-1.39a1 1 0 0 0-1.39-1.44l-1.44 1.39a1 1 0 0 0 .695 1.72ZM11.5 19a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0v-2Zm6.73-2.857a1 1 0 0 0-1.39 1.439l1.439 1.39c.195.188.445.28.695.28a1 1 0 0 0 .695-1.719l-1.439-1.39Zm-12.899 1.39 1.439-1.39a1 1 0 0 1 1.39 1.439l-1.439 1.39a.996.996 0 0 1-.695.28 1 1 0 0 1-.695-1.719Z"
                        fill="#fff"
                    />
                </mask>
                <g mask="url(#a)">
                    <path fill={fill || theme.palette.text.primary} d="M.5 0h24v24H.5z" />
                </g>
            </svg>
        </div>
    )
}

export default Loader
