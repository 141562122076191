import { ISagaModule } from 'redux-dynamic-modules-saga'
import { restore } from './student.actions'
import { STATE_KEY } from './student.consts'
import { reducerMap } from './student.reducer'
import sagas from './student.sagas'

const getStudentModule = (): ISagaModule<StudentState> => {
    return {
        id: STATE_KEY,
        reducerMap,
        sagas: [sagas],
        initialActions: [restore()],
    }
}

export default getStudentModule
