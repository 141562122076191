import { useTheme } from 'components/hooks'

function Sync({ fill, style, ...props }: CustomIconProps) {
    const theme = useTheme()
    return (
        <div style={style} {...props}>
            <svg fill="none" viewBox="0 0 80 80" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M72.276 34.42c-.024.053-.048.106-.07.16-.023.056-.041.114-.06.172-.029.095-.058.189-.107.271-.099.164-.228.3-.357.439l-.11.118c-.04.042-.076.087-.112.133-.05.064-.099.128-.161.18-.187.153-.403.259-.618.364l-.046.023c-.045.022-.088.05-.131.078a.904.904 0 0 1-.192.102 3.275 3.275 0 0 1-1.69.16l-14.166-2.35a3.333 3.333 0 0 1-2.744-3.833c.3-1.814 2.013-3.057 3.833-2.74l5.48.906c-4.15-7.286-12.073-11.936-20.793-11.936-9.863 0-18.553 5.726-22.143 14.583a3.328 3.328 0 0 1-4.34 1.84 3.329 3.329 0 0 1-1.837-4.34C16.526 17.36 27.642 10 40.232 10c11.373 0 21.71 6.233 26.927 15.96l1.23-6.577a3.33 3.33 0 0 1 3.89-2.656 3.331 3.331 0 0 1 2.663 3.89L72.446 33.95c-.033.17-.101.32-.17.47ZM61.912 48.75a3.332 3.332 0 1 1 6.176 2.5C63.475 62.64 52.358 70 39.768 70c-11.373 0-21.71-6.233-26.926-15.96l-1.234 6.574a3.328 3.328 0 0 1-3.89 2.66c-1.81-.337-3-2.08-2.66-3.89l2.497-13.333c.025-.131.082-.248.138-.364.033-.068.065-.136.092-.206.026-.067.05-.136.073-.204.05-.15.1-.299.184-.426.049-.076.113-.137.178-.198a1.77 1.77 0 0 0 .206-.221c.158-.187.316-.373.516-.508.02-.014.043-.023.066-.031.02-.008.04-.015.057-.026.323-.21.67-.373 1.053-.456.024-.006.048-.004.072-.003.021.001.042.002.062 0 .366-.06.736-.097 1.127-.03l14.166 2.353a3.33 3.33 0 0 1 2.743 3.833 3.334 3.334 0 0 1-3.833 2.74l-5.48-.907c4.15 7.287 12.073 11.937 20.794 11.937 9.863 0 18.553-5.727 22.143-14.583Z"
                    fill={fill || theme.palette.text.primary}
                />
            </svg>
        </div>
    )
}

export default Sync
