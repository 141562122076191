import * as localforage from 'localforage'

export const get = (key: string, callback?: () => void) => {
    return localforage.getItem(key, callback)
}

export const set = (key: string, data: string, callback?: () => void) => {
    return localforage.setItem(key, data, callback)
}

export const del = (key: string, callback?: () => void) => {
    return localforage.removeItem(key, callback)
}
